import MailchimpSubscribe from 'react-mailchimp-subscribe'

// custom components
import ComingSoon from '../components/ComingSoon'

const SubscribeNewsletter = () => {
    const MAILCHIMP_URL = `https://app.us13.list-manage.com/subscribe/post?u=addc9aae6921cc9512e4d3712&amp;id=93f6fee7f8&amp;f_id=00e6e2e2f0`

    return (
        <MailchimpSubscribe
            url={MAILCHIMP_URL}
            render={(props) => {
                const { subscribe, status, message } = props || {}
                return (
                    <ComingSoon
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                    />
                )
            }}
        />
    )
}

export default SubscribeNewsletter
