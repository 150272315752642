import React from 'react'

// images
import LogoImg from '../../assets/icons/logo.png'
import LogoImg2 from '../../assets/icons/TheGood.png'

const CustomModal = ({ onClose }) => {
    return (
        <div className='modal-root'>
            <div className='modal-wrapper' onClick={onClose}></div>
            <div className='modal-content'>
                <p className='modal-title'>Congratulations!</p>
                <p className='modal-subtitle'>
                    {/* You are now one step closer to getting free premium service for 2 years! Once the app is launched we will email you so you can be one of the first to register and be one of the lucky ones! Please check your email to confirm your email. */}
                    You are now eligible for some incredible offers from our partners! You will receive a reminder when we are live so you can register and benefit from this special welcome gift!
                </p>
                <div className='modal-logo'>
                    <img src={LogoImg} alt='logo-img' />
                    <img src={LogoImg2} alt='logo-img' />
                </div>
            </div>
        </div>
    )
}

export default CustomModal
