import React, { Fragment, useEffect, useState } from 'react'

// images
import LogoImg from '../../assets/icons/logo.png'
import LogoImg2 from '../../assets/icons/TheGood.png'
import Avatar1 from '../../assets/avatars/avatar1.png'
import Avatar2 from '../../assets/avatars/avatar2.png'
import Avatar3 from '../../assets/avatars/avatar3.png'
import Avatar4 from '../../assets/avatars/avatar4.png'

// custom components
import Timer from '../CountDown'
import CustomModal from '../CustomModal'

const ComingSoon = ({ onValidated, status }) => {
    const [email, setemail] = useState('')
    const [error, setError] = useState('')
    const [loading, isLoading] = useState(false)
    const [showModal, setShowModal] = useState(true)

    const closeModal = () => {
        setShowModal(false)
    }

    const emailhandler = (e) => {
        setemail(e.target.value)
        setError('')
    }

    const handleFormSubmit = () => {
        isLoading(true)
        var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        if (emailRegex.test(email)) {
            onValidated({ EMAIL: email })
        } else if (email === '') {
            setError('Please enter a email address')
            isLoading(false)
        } else {
            setemail('')
            setError('Please enter a valid email address')
            isLoading(false)
        }
    }

    useEffect(() => {
        if (status === 'success') {
            setShowModal(true)
            isLoading(false)
            setemail('')
        } else {
            setShowModal(false)
        }
    }, [status])

    return (
        <Fragment>
            {showModal && <CustomModal onClose={closeModal} />}
            <section className='hero-root'>
                <div className='hero-bg'>
                    <div className='hero-wrapper'>
                        <div className='hero-logo'>
                            <img src={LogoImg} alt='logo-img' />
                            <img src={LogoImg2} alt='logo-img' />
                        </div>
                        <div className='hero-section'>

                            <div className='hero-content-section'>
                                <span className='ready-text'>
                                    A world of personal recommendations at your fingertips
                                </span>
                                <span className='ready-text-label'>
                                    The Good Tip is the ultimate app for saving & sharing tips from people you trust
                                </span>
                            </div>



                            <span className='hero-section-tagline d-desktop'>
                                New app and website available soon
                            </span>
                            <span className='hero-section-tagline d-mobile'>
                                New app and website<br />available soon
                            </span>
                            {/* <Timer /> */}
                            

                            <div className='premiun-section'>
                                <div className='premiun-avatar-wrapper'>
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar1}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar2}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar3}
                                        alt='avatar'
                                    />
                                    <img
                                        className='premiun-avatar'
                                        src={Avatar4}
                                        alt='avatar'
                                    />
                                </div>
                                <div className='premiun-content'>
                                    <span className='premiun-text'>
                                        Pre-subscribe and enjoy special offers from our partners!
                                    </span>
                                </div>
                            </div>

                            <div className='email-section'>
                                <input
                                    type='email'
                                    required
                                    placeholder='Enter Your Email'
                                    className='email-input'
                                    value={email}
                                    onChange={(e) => emailhandler(e)}
                                />
                                <button
                                    className={`sign-up-btn ${error} ${
                                        isLoading
                                            ? 'loading-sign-up-btn'
                                            : 'sign-up-btn'
                                    }`}
                                    onClick={handleFormSubmit}
                                >
                                    {loading ? (
                                        <div className='sign-up-btn-loader' />
                                    ) : (
                                        'Pre-Subscribe Me'
                                    )}
                                </button>
                            </div>
                            {error && (
                                <span className='text-[#d82112] font-extrabold text-xl'>
                                    {error}
                                </span>
                            )}
                            
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ComingSoon
